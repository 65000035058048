.dropdown-menu{
	li{
		a{
			padding:8px;
			i{
				font-size:inherit !important;
			}
		}
	}
}
.panel{
	margin-bottom:10px; 
}
.dropdown-menu .divider{
	margin:0px;
}